import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-21dfa9de"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "container"
};
const _hoisted_2 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_cell = _resolveComponent("van-cell");
  const _component_van_empty = _resolveComponent("van-empty");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.objectlist.length ? (_openBlock(), _createElementBlock("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.objectlist, item => {
    return _openBlock(), _createElementBlock("div", {
      class: "projectdate",
      key: item
    }, [_createVNode(_component_van_cell, {
      title: item.name,
      "is-link": "",
      onClick: $event => $options.goRealtimedynamicDetails(item.code, item.month),
      value: "详情"
    }, null, 8, ["title", "onClick"])]);
  }), 128))])) : (_openBlock(), _createBlock(_component_van_empty, {
    key: 1,
    description: "暂无数据"
  }))]);
}