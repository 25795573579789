import request from '@/utils/request-wxwork';

// 选择月份
export function projectList(params) {
  return request().get('/api/weekly/v2/salesReport/index/projectList', { params });
}

// 首页项目信息
export function projectPlanInfo(params) {
  return request().get('/api/weekly/v2/sales/report/projectPlanInfo', { params });
}

// 激活情况
export function activationRate(params) {
  return request().get('/api/weekly/v2/sales/report/activationRate', { params });
}

// 活跃情况
export function activeRate(params) {
  return request().get('/api/weekly/v2/salesReport/index/att', { params });
}

// 终端计划列表
export function terminalPlanList(params) {
  return request().post('/api/weekly/v2/sales/report/terminalPlanList', { ...params });
}

// 激活详情列表-已激活
export function activationDetail(params) {
  return request().post('/api/weekly/v2/sales/report/activationDetail', { ...params });
}

// 激活详情列表-未激活
export function notActivationDetail(params) {
  return request().post('/api/weekly/v2/sales/report/notActivationDetail', { ...params });
}

// 任务执行情况详情
export function taskPerformInfo(params) {
  return request().post('/api/weekly/v2/sales/report/taskPerformInfo', { ...params });
}

// 任务执行情况详情(考题配置)
export function taskPerformDetail(params) {
  return request().post('/api/weekly/v2/sales/report/taskPerformDetail', { ...params });
}

// 活跃详情（周）
export function activeWeek(params) {
  return request().get('/api/weekly/v2/salesReport/week/att', { params });
}

// 活跃详情（日）
export function activeDay(params) {
  return request().get('/api/weekly/v2/salesReport/day/att', { params });
}

// 获取当前月份所有自然周
export function getWeeks(params) {
  return request().get('/api/weekly/v2/salesReport/week/getWeeks', { params });
}

// 获取活跃详情日维度状态下拉框
export function getStatus(params) {
  return request().get('/api/weekly/v2/salesReport/day/attStatus', { params });
}

// 企业微信终端详情
export function planDetail(params) {
  return request().get('/api/weekly/v2/salesReport/plan/detail', { params });
}

// 省
export function provinceFind(params) {
  return request().get('/api/base/v1/areaselect/province/find', {
    params,
  });
}

// 市
export function cityFind(params) {
  return request().get('/api/base/v1/areaselect/city/find', {
    params,
  });
}

// 区
export function areaFind(params) {
  return request().get('/api/base/v1/areaselect/area/find', {
    params,
  });
}

// 终端计划列表内岗位下拉列表查询
export function positionList(params) {
  return request().get('/api/weekly/v2/sales/report/positionList', { params });
}

// 获取照片
export function photos(data) {
  return request().post('/api/weekly/v2/public/salesReport/day/att/photos', data, { hideLoading: true });
}

// 触点活跃详情
export function taskPerformInfoNew(data) {
  return request().post('/api/weekly/v2/sales/report/taskPerformInfoNew', data);
}

// 地区表
export function taskStaticsTable(data) {
  return request().post('/api/weekly/v2/sales/report/taskStaticsTable', data);
}

// 地区图
export function taskStaticsGraph(data) {
  return request().post('/api/weekly/v2/sales/report/taskStaticsGraph', data);
}
