import "vant/es/empty/style";
import _Empty from "vant/es/empty";
import "vant/es/cell/style";
import _Cell from "vant/es/cell";
import "core-js/modules/es.array.push.js";
import { projectList } from '@/api/wxproject';
export default {
  components: {
    [_Cell.name]: _Cell,
    [_Empty.name]: _Empty
  },
  data() {
    return {
      month: '',
      objectlist: []
    };
  },
  mounted() {
    document.title = '实时动态';
    projectList({
      type: 'now'
    }).then(res => {
      this.month = res.data.data[0].month;
      this.objectlist = res.data.data[0].projectList;
      if (res.data.data[0].projectList.length === 1) {
        this.goRealtimedynamicDetails(res.data.data[0].projectList[0].code, res.data.data[0].projectList[0].month);
      }
    });
  },
  methods: {
    goRealtimedynamicDetails(projectCode, month) {
      this.$router.push({
        path: '/RealtimedynamicDetails',
        query: {
          projectCode: projectCode,
          month: month,
          type: 'now'
        }
      });
    }
  }
};